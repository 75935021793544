import { Injectable } from '@angular/core';
import {
  DepartmentRequest,
  DepartmentResponse,
} from '@modules/settings/models/department.model';
import { Observable } from 'rxjs';
import { ApiService } from '../../../core/services/api.service';
import {
  MainResponse,
  PagedList,
  OptionResponse,
} from '@app/models/response.model';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {
  constructor(private api: ApiService) {}

  createDepartment(request: DepartmentRequest): Observable<MainResponse> {
    return this.api.post('departments/add', request);
  }

  getPagedDepartment(
    pageNo: number,
    pageSize: number
  ): Observable<PagedList<DepartmentResponse[]>> {
    return this.api.getPaged('departments/paged', pageNo, pageSize);
  }

  getDepartments(): Observable<OptionResponse[]> {
    return this.api.get('departments/all');
  }

  editDepartment(request: DepartmentRequest): Observable<MainResponse> {
    return this.api.post('departments/update', request);
  }
}
